<template>
    <section>
        <el-dialog :visible.sync="batchVisibleSync" title="选择库位" width="70%" v-bind="$attrs" @open="open" @click="close">
            <div class="publicSearch p-10">
                <div class="searchBar">
                    <div>
                        <el-select v-model="searchData.type" size="mini" filterable placeholder="库位组类型" clearable>
                            <el-option
                                v-for="item in shelfTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="searchData.cellUseType" size="mini" filterable placeholder="库位类型" clearable>
                            <el-option
                                v-for="item in cellUseTypes"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-select v-model="searchData.status" size="mini" filterable placeholder="是否空库位" clearable>
                            <el-option label="是" value="0"> </el-option>
                            <el-option label="否" value="1"> </el-option>
                        </el-select>
                    </div>
                    <div>
                        <el-input style="width: 300px;" v-model="searchData.keyWord" size="mini" placeholder="库位编码搜索，支持输入空格批量搜索" clearable></el-input>
                    </div>
                    <div>
                        <el-button @click="getList" size="mini" icon="el-icon-search" type="primary">查询</el-button>
                        <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
                    </div>
                </div>
            </div>
        
            <div class="tableBox">
                <el-table highlight-current-row stripe  border v-loading="loading.table" :data="cells" style="width: 100%;margin-top: 20px;">
                    <el-table-column show-overflow-tooltip prop="cellCode" label="库位编码" key="1" min-width="150"></el-table-column>
                    <el-table-column fixed="left" prop="cellUseType" label="库位类型" key="2"> 
                        <template slot-scope="scope">
                            {{ formatListDataByKey(scope.row.cellUseType, false, cellUseTypes, 'label', 'value') }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="shelfCode" label="库位组编码" key="3" min-width="150"></el-table-column>
                    <el-table-column fixed="left" prop="type" key="4" label="库位组类型"> 
                        <template slot-scope="scope">
                            {{ formatListDataByKey(scope.row.type, false, shelfTypes, 'label', 'value' ) }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="areaCode" label="库区编码" key="5" min-width="150"></el-table-column>
                    <el-table-column show-overflow-tooltip prop="areaName" label="库区名称" key="6" min-width="150"> </el-table-column>
                    <el-table-column show-overflow-tooltip prop="status" label="是否空库位" key="7" min-width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 1">否</span>
                            <span v-if="scope.row.status === 0">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" min-width="100" key="11">
                        <template slot-scope="scope">
                            <el-button @click="selectWareBtn(scope.row)" type="text" class="tableBtn" link>选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="pagination-bottom"
                    style="text-align: right;"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :page-sizes="[10,15,20,25]"
                    :current-page.sync="searchData.pageNum"
                    :page-size="searchData.pageSize"
                    layout="sizes, total, prev, pager, next, jumper"
                    :total="searchData.total">
                </el-pagination>
            </div>
            <template slot="footer">
            </template>
        </el-dialog>
    </section>
</template>
  
  <script>
  import {mapState} from "vuex";
  import { formatListDataByKey } from '@/utils/DateUtils.js'
  export default {
    name: "batchQuery",
    props: {
        batchVisible: {
            type: Boolean,
            default: false
        },
        queryForm: {
            type: Object,
            default: () => {
                return {}
            }
        },
        searchRadioData: {
            type: Array,
            default: () => {
                return []
            }
        },
        searchRadio: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: {
                table: true,
            },
            batchVisibleSync: false,
            cells: [],
            searchData: {
                pageNum: 1,
                pageSize: 10,
                total: null,
                keyWord: '',
                wareId: PF.getLocal('wareId', 'number'),
                wareCode: PF.getLocal('wareCode'),
                type: null,
                cellUseType: null,
                status: null,
                keyWord: null,
            },
            currentRow: {}
        }
    },
    computed: {
        ...mapState([
            "cellUseTypes",
            "shelfTypes"
        ]),
    },
    methods: {
        formatListDataByKey,
        open() {
            this.batchVisibleSync = true
            this.getList()
        },
        close() {
            this.batchVisibleSync = false
        },
        getList() {
            this.loading.table = true;
            let { keyWord } = this.searchData
            keyWord = keyWord ? keyWord.split(/,|，|\s+/).join(',') : null
            IOT.getServerData('/cell/infos/list', 'post', {...this.searchData, keyWord}, (ret) => {
                this.loading.table = false;
                if (ret.code === 200) {
                    this.cells = ret.rows;
                    this.searchData.total = ret.total;
                    this.searchData.pageNum = ret.pageNumber
                } else {
                    IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
                }
            });
        },
        // 选择库位
        selectWareBtn(row) {
            this.$emit('selectWareHandle', row)
        },
        handleSelectionChange(val){
            this.selectCells = val;
            this.multipleSelection = val;
        },
        handleSizeChange(val) {
            this.searchData.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getList()
        },
        clearSearchData() {
            this.searchData = {
                pageNum: 1,
                pageSize: 10,
                total: null,
                keyWord: '',
                wareId: PF.getLocal('wareId', 'number'),
                type: null,
                cellUseType: null,
                status: null,
                keyWord: null,
            }
            this.getList()
        }
    }
}
</script>

<style scoped lang="less">

</style>
